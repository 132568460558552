import { path, pipe, __, ifElse, F } from 'ramda'
import { isNameType, strToArray } from './utils'

export const judgeIfRender = (displayRelyGather, formDats) => {
  let displayRes = true

  for (let i = 0; i < displayRelyGather.length; i++) {
    const item = displayRelyGather[i]

    // 如果是 或 关系，则判断之前的结果是不是 true
    // 如果是 true 就直接返回 true
    // 如果是 false 就继续判断
    if (item.type == 'or') {
      if (displayRes) {
        return displayRes
      } else {
        displayRes = judgeByConditions(item.list, formDats)
      }
    } else {
      // 如果是 且 的关系，就判断之前的结果
      // 如果是 true 就继续往下判断
      // 如果是 false 就直接终止
      if (displayRes) {
        displayRes = judgeByConditions(item.list, formDats)
      } else {
        return displayRes
      }
    }
  }

  return displayRes
}

// 判断一组条件
const judgeByConditions = (conditions, formDatas) => {
  return conditions.every(item => {
    return judgeByConditionItem(item, formDatas)
  })
}
// 判断单个条件
const judgeByConditionItem = (item, formDatas) => {
  if (!item.path || !item.type || !item.value || !item.value.length) {
    return true
  }

  const relyFieldValue = getFieldValue(item.path, formDatas)

  // 根据配置的逻辑来判断
  switch (item.type) {
    // >
    case 1:
      return relyFieldValue * 1 > item.value * 1
    // >=
    case 2:
      return relyFieldValue * 1 >= item.value * 1
    // <
    case 3:
      return relyFieldValue * 1 < item.value * 1
    // <=
    case 4:
      return relyFieldValue * 1 <= item.value * 1
    // =
    case 5:
      return relyFieldValue == item.value
    // ⊃ 包含
    case 6:
      if (Array.isArray(relyFieldValue)) {
        return relyFieldValue.some(val => {
          return item.value.includes(val)
        })
      }

      return item.value.includes(relyFieldValue)
  }
}

const getFieldValue = (name, value) => {
  return ifElse(
    isNameType,
    pipe(strToArray, path(__, value)),
    F
  )(name, true)
}
