import request from '@/utils/request2'
import Dao from '@/utils/dao'

function returnHeader() {
  return {
    'x-5a-temp-token': Dao.get('tempToken')
  }
}

export function getDetail(query) {
  return request({
    url: '/pro/claim/temp/form/single/detail',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function saveForDraft(query) {
  return request({
    url: '/pro/claim/temp/form/save',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function saveForSubmit(query) {
  return request({
    url: '/pro/claim/temp/form/submit',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

// 理赔2期，report
export function claimCategory(query) {
  return request({
    url: '/pro/claim/temp/product/category',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function claimCodetable(query) {
  return request({
    url: '/pro/claim/temp/codetable/list',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function claimCustomerSubmit(query) {
  return request({
    url: '/pro/claim/temp/report/customer/submit',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function claimPolicySubmit(query) {
  return request({
    url: '/pro/claim/report/policy/submit',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function claimQueryReportPolicyList(query) {
  return request({
    url: '/pro/claim/temp/policy/query/by/insured/object',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function claimReportPolicyQueryByCode(query) {
  return request({
    url: '/pro/claim/temp/policy/query/by/code',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function getClaimPortalList(query) {
  return request({
    url: '/pro/temp/pfm/form/FormTemplateInfo/list',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}
export function getClaimPortalForm(query) {
  return request({
    url: '/pro/temp/pfm/form/FormTemplateInfo/info',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function saveClaimPortalStep2Form(query) {
  return request({
    url: '/pro/claim/portal/save',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function getClaimPdf(query) {
  return request({
    url: '/pro/claim/portal/pdf',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function sendOtp(query) {
  return request({
    url: '/pro/claim/portal/sendOtp',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function validateOtp(query) {
  return request({
    url: '/pro/claim/portal/validateOtp',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function submittApplicationForm(query) {
  return request({
    url: '/pro/claim/temp/form/submit',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

// 详情页信息
export function queryClaimBasicInfo(query) {
  return request({
    url: '/pro/claim/temp/claim/pro/basic/detail',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

// Claim详情信息
export function queryClaimDetailsInfo(query) {
  return request({
    url: '/pro/claim/temp/claim/pro/form/detail',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

// 详情页信息
export function ebReportBasicInfo(query) {
  return request({
    url: '/pro/claim/temp/report/pro/basic/detail',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

// report详情信息
export function ebReportDetailsInfo(query) {
  return request({
    url: '/pro/claim/temp/report/pro/form/detail',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

// Report详情信息
export function queryReportDetailInfo(query) {
  return request({
    url: '/pro/claim/report/pro/form/detail',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

// Report详情信息
export function queryEvent(query) {
  return request({
    url: '/pro/claim/portal/getEventLs',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}
