<template>
  <Collapse v-model="activeNames" class="fu-form-details-v2">
    <CollapseItem
      v-for="item in finallyColumns"
      :key="item.key"
      :name="item.key"
      :title="item[`name${local}`]"
      :border="false"
    >
      <FuFormDetailsItem :datas="datas[item.key]" :columns="item" />
    </CollapseItem>
  </Collapse>
</template>

<script>
import { Collapse, CollapseItem } from 'vant'
import FuFormDetailsItem from './components/FuFormDetailsItem.vue'
import { judgeIfRender } from './utils/core'

export default {
  name: 'FuFormDetails',
  components: { Collapse, CollapseItem, FuFormDetailsItem },
  props: {
    columns: { type: Object, default: () => { return {} } },
    datas: { type: Object, default: () => { return {} } }
  },
  data() {
    return {
      activeNames: []
    }
  },
  computed: {
    lang() {
      return this.$store.state.base.lang
    },
    local() {
      return this.lang == 'en' ? '' : 'Local'
    },
    finallyColumns() {
      return this.filterGroup(this.columns)
    }
  },
  methods: {
    filterGroup(group = {}) {
      const { columns = [] } = group

      const originColumns = columns.filter(item => !item.fieldJson.isHideInDetails && item.fieldJson.show)
      const fatherColumns = this.filterItem(originColumns)

      fatherColumns.map(item => {
        if (item.columns && item.columns.length) {
          item.columns = this.filterGroup(item)
        }
      })

      return fatherColumns
    },
    filterItem(columns) {
      return columns.filter(item => {
        const { fieldJson = {}} = item
        const { displayRely, displayRelyGather = [] } = fieldJson

        if (displayRely && displayRelyGather.length) {
          return judgeIfRender(displayRelyGather, this.datas)
        } else {
          return true
        }
      })
    }
  }
}
</script>

<style lang="scss">
.fu-form-details-v2 {
  &::after {
    border: none;
  }

  .van-collapse-item + .van-collapse-item {
    margin-top: 4.5px;
  }
  .van-collapse-item {
    background-color: #F9F9F9;
    border-radius: 8.6px;

    .van-icon-arrow {
      color: #D90109;
    }

    .van-cell-group {
      background-color: #F9F9F9;
    }
    .van-cell {
      background-color: #F9F9F9;
      border-radius: 8.6px;
      text-align: left;
      font-size: 10px;
      line-height: 12px;

      .van-cell__title {
        font-weight: 400;
        color: #4B4B4B;

        span {
          font-family: "Roboto";
        }
      }

      .van-cell__value {
        font-weight: 500;

        color: #4B4B4B;
      }
    }

    & > .van-cell {
      align-items: center;
      height: 37px;
      line-height: normal;

      .van-cell__title {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
      }
    }

    .van-collapse-item__content {
      padding: 0;
      background-color: #F9F9F9;
      border-radius: 8.6px;
    }

    .van-cell-group {
      .van-cell {
        padding: 4px 16px;

        .van-cell-group .van-cell {
          padding-right: 0;
        }
      }
    }
  }
}
</style>
