<template>
  <CellGroup :border="false">
    <Cell
      v-for="(cell, index) in columnsLs"
      :key="cell.key"
      :title="cell[`name${local}`]"
      :border="false"
      :class="{
        'fu-cell-object': isTypeField(cell, 'Array') || isTypeField(cell, 'Object')
      }"
    >
      <!-- 数组类型 遍历循环嵌套 -->
      <template v-if="isTypeField(cell, 'Array')">
        <fu-form-details-item
          v-for="(item, idx) in getValue(cell)"
          :key="idx"
          :datas="item"
          :columns="cell.columns[idx] || cell.columns[0]"
        />
      </template>

      <!-- 对象类型 直接嵌套 -->
      <template v-else-if="isTypeField(cell, 'Object')">
        <fu-form-details-item :datas="getCellDatas(cell, index)" :columns="cell" />
      </template>

      <!-- 上传组件 -->
      <template v-else-if="isTypeField(cell, 'Upload')">
        <template v-if="!datas.attachmentPath || !datas.attachmentPath.length">-</template>

        <template v-else>
          <Loading v-if="ossLoading" size="12" />
          <Icon
            v-else
            :name="uploadIcon"
            @click="onPreview()"
          />
        </template>
      </template>

      <!-- 其余类型展示 -->
      <template v-else>{{ getValue(cell) }}</template>

      <!-- 视频预览弹窗 -->
      <Overlay class="preview-overlay" :show="videoPreviewOverlay">
        <Icon
          class="overlay-close-btn"
          name="clear"
          size="20"
          color="#c8c9cc"
          @click="videoPreviewOverlay = false"
        />
        <video
          width="100%"
          height="60%"
          type="video/mp4"
          :src="previewLs[0]"
          muted="muted"
          preload="preload"
          autoplay
          controls="controls"
          controlslist="nodownload"
          disablePictureInPicture="true"
        />
      </Overlay>
    </Cell>
  </CellGroup>
</template>

<script>
import { Cell, CellGroup, Icon, Loading, ImagePreview, Overlay, Notify } from 'vant'
import { formatDateId, formatCurrency } from '@/filters'
import { getFileUrl } from '@/api'

export default {
  name: 'FuFormDetailsItem',
  components: { Cell, CellGroup, Icon, Loading, Overlay },
  props: {
    columns: { type: Object, default: () => { return {} } },
    datas: { type: [Object, Array], default: () => { return {} } }
  },
  data() {
    return {
      ossLoading: false,
      videoPreviewOverlay: false,

      previewLs: []
    }
  },
  computed: {
    lang() {
      return this.$store.state.base.lang
    },
    local() {
      return this.lang == 'en' ? '' : 'Local'
    },
    isObject() {
      return this.columns.renderType == 'Object'
    },
    isArray() {
      return this.columns.renderType == 'Array'
    },
    isUpload() {
      return this.columns.renderType == 'Upload'
    },
    columnsLs() {
      const baseColumns = this.columns.columns || []
      const finallyColumns = baseColumns.filter(item => !item.fieldJson.isHideInDetails && item.fieldJson.show)
      if (!this.isArray) { return finallyColumns }

      const res = []
      const [baseColumnsItem = {}] = finallyColumns

      this.datas.forEach((item, index) => {
        const itemColumns = finallyColumns[index] || baseColumnsItem
        res.push(itemColumns)
      })

      return res
    },
    arrayColumns() {
      if (!this.isArray) { return {} }

      const res = []
      const [columnsItem = {}] = this.columnsLs
      if (Array.isArray(this.datas)) {
        return res
      }
      this.datas.forEach((item, index) => {
        const itemColumns = this.columnsLs[index] || columnsItem
        res.push(itemColumns)
      })

      return res
    },
    // 判断是否是视频
    isVideo() {
      const { attachmentPath = [] } = this.datas || {}

      return attachmentPath.some(item => item.includes('mp4'))
    },
    isImg() {
      const { attachmentPath = [] } = this.datas || {}

      return attachmentPath.some(item => {
        return item.includes('jpg') || item.includes('png') || item.includes('jpeg')
      })
    },
    uploadIcon() {
      if (this.isVideo) {
        return 'video-o'
      } else if (this.isImg) {
        return 'photo-o'
      } else {
        return 'description-o'
      }
    }
  },
  mounted() {},
  created() {},
  methods: {
    getValue(field) {
      const { renderType, key } = field

      // 特殊处理
      if (this.hasFormatCurrency(field)) {
        return formatCurrency(this.datas[key])
      }

      switch (renderType) {
        case 'Date':
          return formatDateId(this.datas[key])
        case 'Address':
          console.log(this.datas)
          var { provinceName, cityName, districtName, provinceNameLocal, cityNameLocal, districtNameLocal } = this.datas[key] || {}
          var addressName = [districtName, cityName, provinceName]
          var addressNameLocal = [districtNameLocal || districtName, cityNameLocal || cityName, provinceNameLocal || provinceName]

          return this.lang == 'en' ? addressName.filter(item => !!item).join(', ') : addressNameLocal.filter(item => !!item).join(', ')
        default:
          return this.datas[`${key}Label${this.local}`] || this.formatValue(field) || '-'
      }
    },
    formatValue(field) {
      const { fieldJson = {}, key } = field
      const itemVal = this.datas[key]

      if (itemVal === '' || itemVal === undefined || itemVal == null) { return itemVal }
      if (!fieldJson.filter) { return itemVal }

      // 货币格式化
      if (fieldJson.filter == 'amount') {
        return formatCurrency(itemVal)
      }

      return itemVal
    },
    isTypeField(field, type) {
      if (!type) { return false }

      return field.renderType == type
    },
    // 是否需要金额格式化
    hasFormatCurrency(field) {
      return ['carValue'].includes(field.key)
    },

    getCellDatas(cell, index) {
      if (this.isArray) {
        return this.datas[index]
      }

      const { fieldJson = {}, key } = cell
      const { flat } = fieldJson

      // 扁平化的对象则直接取父级的值
      if (flat) {
        return this.datas
      } else {
        return this.datas[key]
      }
    },

    // 预览图片
    async onPreview() {
      if (!this.isVideo && !this.isImg) {
        Notify({ type: 'warning', message: this.$t('dynamicForm.uploadInfo100000') })
        return
      }

      if (this.previewLs && this.previewLs.length) {
        this.handlePreview()
        return
      }

      const { attachmentPath } = this.datas
      if (!attachmentPath || !attachmentPath.length) { return }

      this.ossLoading = true
      const imgLs = await this.getFileUrl(attachmentPath)
      this.ossLoading = false

      if (!imgLs || !Array.isArray(imgLs) || !imgLs.length) {
        this.previewLs = null
        return
      }

      this.previewLs = imgLs.map(item => item.fileUrl)
      this.handlePreview()
    },

    handlePreview() {
      if (this.isVideo) {
        this.videoPreviewOverlay = true
      } else {
        ImagePreview({
          images: this.previewLs,
          closeable: true
        })
      }
    },

    // 获取oss文件路径
    async getFileUrl(fileKeys) {
      if (!fileKeys || !fileKeys.length) { return }

      const res = await getFileUrl({
        fileKeys,
        zoom: 'w_50*h_50'
      })

      if (!res) { return }
      return res
    }
  }
}
</script>

<style lang="scss">
.preview-overlay {
  display: flex;
  align-items: center;
}
.overlay-close-btn {
  position: absolute;
  top: .2rem;
  right: .2rem;
}
.van-cell.fu-cell-object {
  flex-wrap: wrap;

  & > .van-cell__title{
    color: #4B4B4B
  }

  & > .van-cell__title,
  & > .van-cell__value {
    flex-shrink: 0;
    flex: auto;
    width: 100%;
  }
}
</style>
